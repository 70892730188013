import logo from "./logo.svg";
import "./App.css";
import Homepage from "./Homepage";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <p>This domain may be for sale directly from the owner.</p>
        <a
          className='App-link'
          href={"mailto:hello@" + window.location.hostname}
          target='_blank'
          rel='noopener noreferrer'
        >
          Email the owner at hello@{window.location.hostname}
        </a>
      </header>
    </div>
  );
}

export default App;
